@import "views";

.mediaTableMenu {
  z-index: 10;
  max-width: 250px;
  ul {
    @include container;
    min-width: 250px;
    li {
      @include span(12);

      input {
        @include span(2); }
      label {
        @include span(10 last); } } } }
