.item__row {
  @include display(flex);
  @include flex-direction(row); }

.item__column {
  @include display(flex);
  @include flex-direction(column); }

.item__nogrow {
  @include flex-grow(0); }

.item__noshrink {
  @include flex-shrink(1); }

.item__grow {
  @include flex-grow(1); }


//search Global autocomplete
ul.searchGlobal-autocomplete {
  li {
    md-autocomplete-parent-scope {
      @include container();

      .col-1 {
        @include span(8);
        img {
          width: 40px;
          height: auto;
          margin-right: 10px; } }

      .col-2 {
        @include span(1); }
      .col-3 {
        @include span(3 last); } } } }
