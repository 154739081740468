body, html {
  -webkit-font-smoothing: inherit!important;
  -moz-osx-font-smoothing: inherit!important;
  -moz-osx-font-smoothing: inherit!important;
  font-size: 16px!important; }

.ie10 span[flex] {
  display: block; }
.ie10 md-dialog {
  padding: 0px 20px; }
.ie10 .ie10FixDivWithSidenav {
  display: inline-block;
  width: calc(100% - 280px); }

.btn:focus,
.btn.focus,
.btn:active:focus,
.btn:active.focus,
.btn.active:focus,
.btn.active.focus {
  outline: none!important;
  outline: none!important;
  outline-offset: 0!important; }

*:focus {
  outline: none!important;
  outline-offset: 0!important; }

.angular-app .filters {
  background-color: #000;
  padding: 8px;
  @include display(flex);
  @include flex-direction(column);
  @include susy-breakpoint($bkp-sm) {
    @include flex-direction(row);
    @include flex-wrap(nowrap); }

  button.btn, button.btn:focus, button.btn:hover {
    margin-bottom: 6px;
    border-radius: 0;
    background-color: #000;
    color: white;
    margin: 2px;
    border: 1px solid tint(black, 20);

    &.active {
      background-color: white;
      color: black; } } }

.live-button {
  min-width: 100px;
  .live-pulse .inner {
    top: 5px;
    right: 0; } }

.period {
  padding: 12px;
  font-weight: 600;
  font-size: 22px;
  background-color: white;
  text-align: center;
  border-bottom: 1px solid $line-gray;
  margin-bottom: 1px; }



//Makes images responsive.
img {
  max-width: 100%;
  height: auto; }

body a, body a:hover {
  text-decoration: none; }

nav {
  #logo {
    width: 205px;

    @include susy-breakpoint($bkp-md) {
      width: 300px;
      position: absolute;
      top: -25px;
      height: auto; } } }

//remove the Status on the entityreference lot field
//Add page
.page-node-add-auction {
  .field-type-entityreference.field-name-field-lots {
    .form-item-field-lots-und-form-status {
 } }      //display: none

  .field-name-field-set-on-sale {
 } }    //display: none
//Edit page
.page-node-edit.node-type-auction {
  .form-item-field-lots-und-entities-0-form-status {
 } }    //display: none

.page-node-edit {
  .field-type-commerce-product-reference {
    fieldset {
      box-shadow: none;
      border-top: none!important;
      margin: 2px 0;
      padding: 8px 0;

      legend {
        display: none; } } }

  .form-item-field-lots-und-entities-0-form-field-product-und-form-status, .form-item-field-product-und-form-status {
    display: none; } }




.text.timeline {
  font-size: 14px;
  font-weight: 600;
  padding: 4px 0;
  color: $text-gray;
  &.past {
    color: darkred; }
  &.future {
    color: darkkhaki; } }

.edit-container {
  position: relative;
  @include edit-button; }

h3.title {
  font-weight: bold;
  font-size: 16px;
  margin: 10px 0 2px 0; }

.text-bold {
  font-weight: 600; }

.text-extra-bold {
  font-weight: 800; }

.date-separator {
  color: $primary;
  font-weight: bold; }

i.icon {
  padding: 0 5px 0 0;
  color: $primary;
  display: inline-block; }

.icon-right {
  padding: 0 0 0 5px; }

.icon-black {
  color: $text-dark-gray; }

i.icon-button {
  display: block;
  padding: 8px; }

i.favourite {
  font-size: 20px;
  color: $text-gray;

  &.fa-heart {
    color: $primary; } }

.side-box {
  margin: 5px 0;

  .side-box-content {
    padding: 15px; } }

.side-box.white {
  .side-box-content {
    background-color: #fff; } }

.side-box.gray {
  .side-box-content {
    background-color: $light-gray; } }

.black-header {
  background-color: #000;
  padding: 15px 5px;
  text-align: center;
  color: whitesmoke;
  font-size: 16px; }

.primary-header {
  background-color: $primary;
  padding: 15px 5px;
  text-align: center;
  color: whitesmoke;
  font-size: 16px; }

.no-item-found {
  font-weight: bold;
  color: $text-gray;
  text-align: center;
  padding: 30px 10px; }

.watch-button {
  button {
    @include btn-bordered; } }

.auction-timeout {
  span {
    padding-right: 8px; } }

.expiring timer span {
  color: orangered; }

.suspended {
  color: orangered; }

.closed {
  color: darkorange; }

.leading {
  .alert {
    margin-bottom: 0px;
    margin-top: 10px; } }
