.user-tab {

  .side-box {
    margin: 0; }

  .item__row {

    &.active {
      a {
        color: $primary;
        text-decoration: none; } }


    a {
      @include display(flex);
      @include flex-direction(row);
      @include justify-content(space-between);
      @include align-content(center);
      @include flex-grow(1);
      padding: 4px 0;
      color: $text-dark-gray;
      font-weight: 500; }

    &:hover {
      text-decoration: none; } } }
