.items {
  //VIEW MODE INDIPENDENT
  @include container;

  .item-container {
    @include margin(0 0 10px 0); }

  .item {
    @include position(relative);
    @include edit-button;
    background-color: $light-gray;

    span {
      color: $text-gray;
      font-weight: 600; }

    a.leading, .leading, a.leading > *, .leading > * {
      color: forestgreen; }

    a.not-leading, .not-leading, a.not-leading > *, .not-leading > * {
      color: #920000; } }

  .user-status {
    padding: 4px 4px 4px 0;
    margin-top: 6px;

    &.up {
      color: green;
      font-size: 18px;
      font-weight: 700; }

    &.down {}
    color: darkgoldenrod; } }




.items {

  //LIST MODE
  &.list {
    .item {
      @include display(flex);
      @include flex-direction(column);

      @include susy-breakpoint(668px) {
        @include flex-direction(row); }

      //Image
      .item-image {
        @include flex-grow(1);
        @include order(1);
        width: 100%;
        @include susy-breakpoint(668px) {
          width: 100px;
          @include flex-grow(0);
          @include flex-shrink(0); } }

      //Content
      .item-2 {
        @include flex-grow(1);
        @include order(2);
        padding: 0 20px;
        .item-name {
          padding-bottom: 10px; }
        .item-info {
          i {
            padding-left: 0; } } }

      //LastBid
      .item-3 {
        @include order(3);
        @include display(flex);
        @include flex-direction(column);
        @include align-items(center);
        @include justify-content(center);
        @include flex-grow(0);
        @include padding(10px 30px);
        color: $text-gray;
        font-weight: bold;
        @include susy-breakpoint(668px) {
          @include flex-basis(180px);
          padding: 0 30px; }

        .amount {
          color: $text-dark-gray; } }

      //controls
      .item-4 {
        @include order(4);
        @include padding(15px);
        @include align-items(center);
        @include justify-content(center);
        @include flex-grow(0);
        @include display(flex);
        @include flex-direction(row);
        > * {
          padding: 0 10px; }

        .watch-button {
          @include order(1); } } } }

  //GALLERY MODE
  &.gallery {
    .item-image {
      width: 100%; }

    .item {
      display: block;
      //+display(flex)
      //+flex-direction(column)

      .item-image {
        @include order(0); }

      //content
      .item-2 {
        background-color: white;
        @include order(2);
        padding: 15px 0 20px 20px;
        .item-name {
          padding-bottom: 25px;
          min-height: 70px; }
        .item-info {
          i {
            padding-left: 0; } } }


      //bid
      .item-3 {
        @include order(1);
        @include display(flex);
        @include flex-direction(row);
        background-color: $primary;
        color: white;
        padding: 15px 0 15px 20px;
        font-weight: 500;
        > * {
          padding-right: 5px; } }

      //controls
      .item-4 {
        background-color: white;
        @include order(3);
        @include display(flex);
        @include flex-direction(row);
        @include justify-content(space-between);
        @include align-items(center);
        padding: 15px 20px; } }

    //BREAKPOINTS
    @include container;
    .item-container {
      @include span(12);
      @include susy-breakpoint($bk-600) {
        @include gallery(6); }

      @include susy-breakpoint($bkp-md) {
        @include gallery(4); } } }

  &.list, &.gallery {
    .lot-live-item {
      //border: 3px solid rgba($primary, .5)
      margin-bottom: 20px; }
    .item.live {
      & ~ .item-bid-section {
        background-color: white;

        .bid-header {
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          @media all and (min-width: 768px) {
            flex-direction: row; } }

        form {
          //border-top: 1px solid #dadada
          padding: 15px; }
        .info-exander {
          i {
            color: $primary;
            width: 35px;
            height: 35px;
            text-align: center;
            line-height: 18px;
            //float: right
            display: inline-block;
            border: 1px solid $primary;
            border-radius: 60%; } }

        .bid-inputs {
          @media all and (min-width: 768px) {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .item__row {
              flex-grow: 1;
              margin-left: 10px;
              i.fa {}

              h4 {
                margin: 0; }
              .item__sub__row, .item__sub_row {
                margin-bottom: 8px; }
              .item__sub__row.item__spaced {
                max-width: 300px;
                display: flex;
                input {
                  max-width: inherit;
                  display: flex;
                  flex-grow: 1; } } } } } } } } }
