.home-component.lots {

  .lot-item {
    background-color: #fff; }

  .item-info {
    font-weight: 600;
    color: $text-gray;
    font-size: 14px; }

  .side-box {
    margin-top: 0;

    .side-box-content {
      background-color: white; } }

  .side-box.all-lots {
    .black-header {
      @include display(flex);
      @include flex-direction(row);
      @include justify-content(space-between);
      padding: 15px 30px; } } }

.top-paging {
  ul.pagination {
    margin: 5px 0; } }
