.side__block {

  &.white {
    background: white; }
  &.gray {
    background: $light-gray; }


  //Layout
  .side__block__header {}

  .side__block__content {
    padding: 15px 20px;
    @include container;

    .side__block__item {
      @include span(12);

      .side__block__inner__item {
        @include span(12);
        padding: 12px 0; }

      md-divider {
        @include span(12); } } }

  //elements
  .side__block__tile {}

  .side__block__image {} }
