.page-contact {
  #page-header {
    margin: 55px 0 40px;
    .page-header {
      border-bottom-width: 2px;
      border-bottom-color: $line-gray; } }

  h1.title {
    text-align: center;
    font-size: 26px;
    padding-bottom: 12px; }
  #content {
    @include container;

    .layout-left {
      margin-bottom: 60px;

      @include susy-breakpoint($bkp-md) {
        @include span(3.5); } }

    .layout-main-right {
      @include susy-breakpoint($bkp-md) {
        @include span(8 last); } } }



  //ELEMENTS


  /*general information block*/
  .contact-left {
    .block {
      .side-box-content {
        h3 {
          color: $primary;
          font-weight: 600; }

        pre {
          font-weight: 600;
          color: $text-gray;
          padding: 0;
          margin-top: 30px;
          background: none;
          border: none;
          font-family: inherit;
          font-size: 16px;
          line-height: 28px; } } } } }

form.contact-form {

  .form-item {
    margin-bottom: 10px; }

  input[type=submit] {
    padding: 8px 50px; }

  textarea {
    min-height: 170px; }

  label {
    color: $text-gray;
    font-weight: 600;
    font-size: 14px; }

  input {
    max-width: 100%;
    box-shadow: none;
    padding: 4px 8px; }

  input, textarea {
    border-radius: 0;
    border: 1px solid $line-gray; }

  @include susy-breakpoint($bkp-lg) {
    .form-item-name {
      @include span(6); }
    .form-item-mail {
      @include span(6 last); }

    textarea {
      min-height: 240px; } } }
