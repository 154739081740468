.lots-outer {
  @include display(flex);
  @include flex-direction(column);
  width: 100%;
  padding: 0 20px;

  .repeater {
    width: 100%;
 }    //max-width: 400px

  @include susy-breakpoint($bk-600) {
    @include flex-direction(row);
    @include flex-wrap(wrap); }


  //directive
  lot-item-featured {
    @include span(12);
    padding: 20px 0;

    //inner
    .lot-item-inner {
      position: relative;
      @include clearfix;

      @include edit-button;

      > * {
        box-sizing: content-box; }

      .image {
        //max-width: px
        padding: 0;
        img {
          width: 100%;
          height: auto; } } } }


  md-divider {
    @include span(12); } }
