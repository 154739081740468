.page .ng-gallery {
  margin-top: 30px;
  @include display(flex);
  @include flex-direction(row);
  @include justify-content(center);
  @include flex-wrap(wrap);

  img {
    height: 150px; }

  img {
    margin-right: 30px;
    margin-bottom: 30px; } }

