.user-lots {

  .layout__main__center {
    @include order(1);
    padding-bottom: 8px; }

  .layout__right__side {
    @include order(0);
    padding: 20px 0; }

  @include susy-breakpoint($bkp-md) {
    .layout__main__center {
      @include order(0); }

    .layout__right__side {
      @include order(1);
      padding-bottom: 0; } } }

