.last-minute timer span {
  -webkit-animation: pulsate 1s ease-out;
  -webkit-animation-iteration-count: infinite;
}

.pulse {
  @include animation(pulse .6s ease-out 3);
  color: orangered;
}

/* The starting CSS styles for the enter animation */
.myfade.ng-enter {
  transition: 0.1s linear all;
  opacity: 0;
}

/* The finishing CSS styles for the enter animation */
.myfade.ng-enter.ng-enter-active {
  opacity: 1;
}

.myfade.ng-leave {
  //transition: 0.1s linear all;
  opacity: 1;

}
.myfade.ng-leave.ng-leave-active {
  opacity: 0;
}

.myfade.ng-enter-stagger {
  /* this will have a 100ms delay between each successive leave animation */
  transition-delay: 0.1s; /* As of 1.4.4, this must always be set: it signals ngAnimate
                         to not accidentally inherit a delay property from another CSS class */
  transition-duration: 0s;
}



@-webkit-keyframes pulsate {
  0% {-webkit-transform: scale(0.1, 0.1); opacity: 0.0;}
  50% {opacity: 1.0;}
  100% {-webkit-transform: scale(1.2, 1.2); opacity: 0.0;}
}

@-webkit-keyframes pulse {
  0% {-webkit-transform: scale(0.1, 0.1); opacity: 0.0;}
  50% {opacity: 1.0;}
  100% {-webkit-transform: scale(1.2, 1.2); opacity: 0.0;}
}

.live-pulse {
  position: relative;
  .inner {
    border: 2px solid #80c31a;
    -webkit-border-radius: 30px;
    height: 10px;
    width: 10px;
    position: absolute;
    right: 15px;
    top: 6px;
    -webkit-animation: livePulsate 1.5s ease-out;
    -webkit-animation-iteration-count: infinite;
    opacity: 0.0;
    z-index: 10;
  }
}
@-webkit-keyframes livePulsate {
  0% {-webkit-transform: scale(0.1, 0.1); opacity: 0.0;}
  50% {opacity: .8;}
  100% {-webkit-transform: scale(1.1, 1.1); opacity: 0.0;}
}
