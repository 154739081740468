
@mixin btn-bordered {
  background: none;
  border: none;
  border: 1px solid $primary;
  padding: .6em 1.5em;
  border-radius: 0;
  //font-size: .9em
  font-weight: 500;
  color: $primary;
  &:hover {
    background: $primary;
    color: whitesmoke;
    border-color: $primary; } }

@mixin adminEdit {
  position: absolute; }

@mixin edit-button {
  .edit {
    position: absolute;
    right: 10px;
    top: 5px;
    display: none;
    font-size: 14px;
    padding: 5px 10px;
    background-color: white;
    border-radius: 3px;
    box-shadow: 0 0 5px -1px gray; }

  &:hover .edit {
    display: block; } }
