html body {

  #content.container {
    min-height: 400px; }

  #footer {
    .block__title {
      color: white; }

    .block__content {
      color: $text-gray; } } }

