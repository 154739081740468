auction-single-page {

  .progress {
    background: none; }

  lot-list {
    margin-top: 5px;
    position: relative;

    .progress {
      position: absolute;
      height: 20px;
      width: 100%;
      top: -32px;
      padding-right: 20px; } }

  footer .container .item__flex {
    @include display(flex);
    @include flex-direction(column);

    @include susy-breakpoint($bkp-md) {
      @include flex-direction(row);

      lot-list {
        @include flex-grow(1);
        padding-right: 20px; }
      categories {
        @include flex-grow(1);
        min-width: 300px;
        max-width: 350px; } } } }

