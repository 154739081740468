#messages .alert {
  margin: 20px 0; }

body.page-lots, body.page-auctions {
  #content.container {
    padding: 0;
    margin: 0;
    width: 100%; } }

body .page-navigation {

  .navigation__content {
    background-color: $line-gray;
    border-radius: 0 0 6px 6px;
    @include display(flex);
    @include flex-direction(column);
    @include justify-content(space-between);
    @include align-items(center);
    padding: 10px 0;

    @include susy-breakpoint($bkp-md) {
      @include flex-direction(row); } }

  .breadcrumbs {
    padding: 20px 20px;
    @include susy-breakpoint($bkp-md) {
      padding: 0 10px;
      max-width: 760px; }
    color: $text-gray;
    font-weight: 500;
    font-size: 14px;
    a {
      text-decoration: underline;
      color: $text-gray; }

    span {
      padding: 0 2px; } }

  .lot__navigation {
    padding-right: 15px;
    width: 100%;
    text-align: center;
    > span {
      display: block;
      padding: 8px 0;
      width: 100%; }

    a, span {
      color: $text-gray;
      text-decoration: none;
      font-weight: 500; }

    i.fa {
      padding: 0 10px; }
    span {
 }      //padding: 0 25px

    @include susy-breakpoint($bkp-md) {
      width: inherit;
      span {
        display: inline;
        padding: 0 25px; } } } }

.page {
  padding-top: 40px;
  padding-bottom: 0;
  margin-bottom: 0;
  @include container();
  @include span(12);

  .page-content {
    padding-bottom: 25px;

    h1, .header-title {
      font-size: 25px;
      color: $text-dark-gray;
      margin-bottom: 8px;
      margin-top: 0; }

    h3, .sub-header {
      margin-top: 10px;
      margin-bottom: 15px;
      font-size: 20px;
      font-weight: 600; }

    .description {
      font-size: 15px;
      padding-top: 20px;
      font-weight: 500;
      color: $text-gray;
      text-align: justify; }

    .description {
      h1,h2,h3,h4,h5,h6 {
        color: $text-dark-gray; } } } }


body .page-content {
  //BREAKPOINTS

  //small
  @include display(flex);
  @include flex-direction(column);
  //Medium
  @include susy-breakpoint($bkp-sm) {
    @include display(flex);
    @include flex-direction(row);
    .page_header {
      @include flex-grow(1);

      padding-right: 50px; }
    .page-side {
      @include flex-grow(0);
      min-width: 340px; } } }


.page-info {
  //padding: 60px 0
  border-bottom: 2px solid $line-gray;
  padding-bottom: 10px;
  margin-bottom: 5px; }


.page-list {
  padding: 5px 0 20px 0; }

.page-list-pushed {
  @include span(12);
  @include susy-breakpoint($bkp-lg) {
    @include span(6.5); } }

.page-list-side {
  @include span(12);

  @include susy-breakpoint($bkp-lg) {
    @include span(5);
    @include last(); } }

.page-footer {
  padding: 10px 0;
  background-color: #fff; }
