.category__item {
  @include display(flex);
  @include flex-direction(column);

  .item__title {
    @include display(flex);
    @include flex-direction(row);
    @include align-items(center);
    position: relative;

    .item__float {
      position: absolute;
      left: -18px; } }

  span {
    font-size: 16px;
    font-weight: 500;
    color: $text-dark-gray; }


  .title {
    padding-right: 10px;
    @include flex-grow(1); }

  .item__row {
    padding: 8px 0 8px 0; }

  .item_indended {
    padding-left: 0px;
    span {
      color: $text-gray;
      font-size: 14px; } }
  //
  //.expanded
  //  .title
  //    color: $primary

  .selected .title {
    color: $primary; } }
