body #footer {
  padding-top: 3.5em;
  a {
    color: $text-gray;
    text-decoration: underline; }

  .footer__top {
    padding-bottom: 60px;
    border-bottom: 1px solid #414646;
    margin-bottom: 10px;
    > .region {
      @include display(flex);
      @include flex-direction(column);
      @include flex-wrap(wrap);
      position: relative;

      .block__title {
        font-size: 16px; }

      > div {
        padding-bottom: 8px; }

      > div:last-child {
        display: none; }

      .block__content {
        a {
          padding: 0 0 8px 0;
          background: none; } }

      .block--none {
        @include order(6);
        @include flex-grow(2);
        width: 100%;
        padding: 0;
        margin: 0px 0;
        z-index: 10;
        p {
          margin: 0; } }

      @include susy-breakpoint($bkp-md) {
        .block__title {
          padding-bottom: 18px; }

        @include flex-direction(row);
        @include justify-content(space-between);
        //+flex-wrap(wrap)
        > div {
          @include flex-grow(0); }
        > div:nth-child(3) {
          @include flex-grow(0);
          max-width: 380px; }
        > div:last-child {
          display: block;
          @include flex-grow(1);
          @include flex-shrink(1);
          text-align: right; }


        > div:not(:last-child) {
 }          //padding-right: 4em

        div.block--none {
          position: relative;
          float: right;
          //top: -60px
          //text-align: right
          //right: 10px
          padding-right: 0; } } } }

  .footer__bottom {
    padding-top: 0;
    a {
      text-decoration: none;
      background: none; }

    .region {
      @include display(flex);
      @include flex-direction(column);

      font-size: 14px;


      .block-menu {
        a {
          text-decoration: underline;
          padding-left: 0; } }

      @include susy-breakpoint($bkp-md) {
        @include flex-direction(row);
        @include flex-wrap(nowrap);
        @include justify-content(space-between);

        a {
          padding-right: 0; }

        .block-block {
          padding: 10px 0; }


        .block-menu {

          .block__content {
            ul {
              @include display(flex);
              @include flex-grow(1); }

            li {
              padding-left: 10px;
              margin: 0 8px;
              position: relative;

              &:not(:first-child)::before {
                display: inline-block;
                content: "\2022";
                font-size: 18px;
                @include size(5px);
                color: $primary;
                left: -6px;
                top: 8px;
                position: absolute;
                text-decoration: none; } } } } } } } }

