.navbar-auction {
  height: 110px;
  @include susy-breakpoint($bk-960) {
    height: auto;
    .links {
      @include flex-shrink(0); } } }

.navbar-bopa {
  //height: 110px
  padding: 0px 0 8px 0;
  margin-bottom: 20px;

  border-bottom: 2px solid $line-gray;

  .cancel-search {
    padding: 8px; }

  em {
    display: inline-block;
    padding: 3px 12px;
    text-align: center;
    margin-right: 5px;
    background: tint(lightgrey, 30);
    border: 1px solid shade(lightgrey, 2);
    color: shade(lightgrey, 40);
    border-radius: 3px; }

  em.text-search {}

  em.category-name {}

  .search-input {
    padding: 10px 0;
    @include susy-breakpoint($bk-960) {
      max-width: 400px;
      height: 38px; }

    span {
      background: #FFF;
      color: $text-gray; }
    input {
      border-right-style: none;
      height: 38px;
      border-radius: 0;
      padding: 0 10px; } }

  .links {
    @include padding(10px 0);
    @include margin(0);

    li {
      font-size: 16px; }

    i.icon {
      color: black; }

    li.active {
      i, span {
        color: $primary; } } } }
