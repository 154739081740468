div.featured-lots {
  background-color: #fff;
  .content {
    //+display(flex)
    //md-divider
    //+display(flex)
 }    //+flex-direction(column)
  .repeater {
    margin: 10px 0; }

  md-divider {
    margin: 10px 0; } }
