@import "../../bower_components/susy/sass/susy";

$susy: (
        columns: 12,
        gutter: 1/4,
        math: fluid,
        output: float,
        gutter-position: after,
        last-flow: to,
        //debug: (image: show,
        //        color: rgba(#66f, .25),
        //        output: overlay,
        //        toggle: top right),
        use-custom: (background-image: true,
                background-options: false,
                box-sizing: true,
                clearfix: true,
                rem: true)
);
