.user-lots {

  .progress-linear {
    width: 100%; }

  .items {
    .item {
      background-color: #fff; } } }

.layout__wrapper {
  @include display(flex);
  @include flex-direction(column);

  @include susy-breakpoint($bkp-md) {
    @include flex-direction(row);
    @include flex-wrap(nowrap);

    .layout__main__center {
      @include flex-grow(1);
      padding-right: 20px;
      padding-top: 20px; }

    .layout__right__side {
      @include flex-grow(0);
      @include flex-shrink(0);
      padding-top: 20px;
      width: 350px; } } }
