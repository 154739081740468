footer {
  .lots-count {
    font-weight: 600;
    font-size: 14px;
    padding-right: 10px; }
  i {
    padding: 0 5px;
    color: $primary; }
  span {
    font-weight: 600;
    font-size: 14px;
    color: $text-gray; } }


.auctions {
  .right-block {
    .location {
      @include susy-breakpoint($bk-960) {
        @include flex-basis(180px);
        padding: 10px .5em; }

      padding: 10px 2em;
      text-align: center;
      .city {
        font-weight: bold;
        text-transform: capitalize; }
      .country {
        color: $text-gray; } } }

  .watch-button {
    padding: 0 1em;
    font-size: 1.1em;
    button {
      @include btn-bordered; } } }


.auctions.list {
  .auction-item {
    background-color: white;
    padding: 0em;
    margin: 0 0 1em 0;
    position: relative;

    @include edit-button;

    .wrapper {
      @include susy-breakpoint(600px) {
        @include flex-wrap(nowrap); } }

    .image {
      @include susy-breakpoint(600px) {
        width: 100px; }
      img {
        max-width: 100%;
        height: auto; } }


    //middle-block
    .middle-block {
      padding: 3px 0 3px 10px;

      header {
        div.description {
          color: $text-gray;
          font-weight: 400;
          padding-bottom: 10px;
          font-size: 0.9em; } } }

 } }    //Right Block


.auctions.gallery {
  display: block;
  @include container();

  //The repeated item
  .auction-container {
    @include span(12);
    @include margin(0 0 30px 0);
    background-color: white;

    .auction-item {
      position: relative; }

    //Remove flexbox
    .auction-item, .wrapper {
      display: block;
      @include edit-button;
      .image {
        img {
          max-width: 100%; } } }

    //MIDDLE
    .middle-block {
      header {
        .title {
          @include display(flex);
          @include flex-direction(column);
          @include align-self(center);
          @include justify-content(center);
          background-color: $primary;
          margin: 0;
          min-height: 70px;
          line-height: 20px;
          padding: 15px;
          color: white; }

        .description {
          padding: 15px 15px 0; } }

      footer {
        display: block;
        padding: 15px 15px 0;

        .auction-timeout {
          min-height: 40px;
          display: table;
          i {
            width: 18px;
            padding-top: 3px;
            display: table-cell;
            vertical-align: top; }
          span {
            margin-top: 0;
            padding-top: 2px;

            display: table-cell;
            vertical-align: top; } } } }

    .right-block {
      padding: 10px 15px 25px;
      > div {
        text-align: center;
        padding: 5px 0; }
      .watch-button {
        padding: 10px; } }



    //BREAKPOINTS
    @include susy-breakpoint(600px) {
      @include gallery(6 of 12);

      .right-block {
        display: block; } }

    @include susy-breakpoint(900px) {
      @include gallery(4 of 12); } } }


.last-container {
  @include align-self(center);
  padding-bottom: 12px;
  @include susy-breakpoint($bk-600) {
    padding-bottom: 0; } }


//search page
.search-page {
  md-autocomplete {
    height: 50px; }
  md-autocomplete-wrap {
    height: 50px; }

  md-autocomplete input:not(.md-input) {
    font-size: 1.1em;
    height: 50px;
    padding: 20px; }

  .results.list {
    .item, .auction-item {
      background-color: white; }

    .item .item-4 {
      .favourite-container {
        width: 55px; } }

    .item .item-2 {
        padding: 0 10px; }

    .item-info {
      font-size: 0.9em; }

    .auction-item {
      margin-bottom: 0; }

    .middle-block {
      flex-basis: 22%; }

    .last-container {
      flex-basis: 5%;


      .right-block {
        .location {
          flex-basis: 294px; } } } } }
