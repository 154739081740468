.page.faq-page {

  .page-header {
    border-bottom: 2px solid #E6E6E6;

    h1 {
      font-size: 28px; } }

  .page-content {
    .faq__block {
      padding-top: 30px;
      width: 100%;
      max-width: 600px;
      margin: auto;

      .vAccordion--default,
      v-pane-header:focus {
        color: $text-dark-gray;
        font-weight: 500; }

      v-accordion {
        background-color: #fff;

        v-pane-header {
          padding: 10px 20px;
          margin-bottom: 0px;

          &:hover {
            color: $text-dark-gray; }

          &::before, &::after {
            color: white;
            background-color: $primary;
            right: 20px; } }


        v-pane.is-expanded {
          v-pane-header {
            background-color: $primary;
            color: white;
            border: none;

            &::hover {
              color: white; }

            &::before, &::after {
              color: white;
              background-color: #fff;
              right: 20px; } }

          v-pane-content {
            padding: 20px 20px 20px 30px;
            border-bottom: 2px solid #d8d8d8;
            color: $text-gray; } } } } } }
