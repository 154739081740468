.lot-bid {
  @include display(flex);
  @include flex-direction(column);

  .small {
    color: $text-gray; }

  .alert {
    span {
      padding: 3px 0;
      display: inline-block; }

    span.link {
      padding-right: 8px; } }

  button {
    @include btn-bordered;
    min-width: 100px; }


  .item__row {
    @include display(flex);
    @include flex-direction(column);
    margin: 3px 0;

    .btn {
      margin-bottom: 0!important; }


    h4 {
      font-size: 16px; }

    i.fa {
      padding: 0 8px;
      color: $text-gray; }

    .alert {
      padding: 8px;
      font-size: 14px;
      i {
        padding: 0 8px 0 0; } }

    input.ng-invalid, input.ng-invalid-min {
      border-color: red; }

    .item__error, .item__messages {
      div.alert {
        @include display(flex);
        @include flex-grow(1);
        @include flex-direction(row);
        @include justify-content(center);
        @include align-items(center);

        position: relative;
        padding: 45px 8px 20px 5px;

        ul {
          padding-left: 5px;
          list-style: none; }

        > i {
          font-size: 16px;
          color: darkslategrey; }

        .float-close {
          position: absolute;
          right: -2px;
          top: -2px;
          padding: 10px; } } } }


  .item__sub__row {
    @include display(flex);
    @include flex-direction(row);
    @include align-items(center);

    .leading-info {
      width: 100%; }

    &.item__spaced {
      @include justify-content(space-between);
      @include align-items(stretch);

      input {
        max-width: 100%;
        @include flex-grow(1); }

      @include susy-breakpoint($bkp-sm) {
        @include justify-content(space-between);
        @include align-content(center);

        input {
          max-width: 120px;
          @include flex-grow(0); } } }

    &.details {
      @include display(flex);
      @include flex-direction(column);
      @include align-items(stretch);
      @include flex-grow(1);
      padding-top: 20px;

      .name, .value {
        font-weight: 600; }

      .item__spaced__row {
        @include display(flex);
        @include flex-direction(row);
        @include justify-content(space-between);
        padding: 10px 0;
        &:not(:last-child) {
          border-bottom: 1px solid $line-gray; } } } }

  .next-bid, .high-bid {
    padding: 0 5px; }

  .high-bid {
    font-size: 18px;
    font-weight: 700; } }

//If expired hide some elements
.lot-page.expired {
  .side-box-content {
    padding: 0; } }

.lot-suspended, .auction-suspended {}

.lot-closed, .auction-closed {}

.selling-awarded, .selling-processed {}



//Bid Dialog
.bid-dialog {
  .description {
    font-weight: 700; }

  .prices {
    div.dialog__row {
      border-bottom: 1px solid $light-gray;
      font-size: 14px;
      padding: 8px 0;
      font-size: 16px;

      @include display(flex);
      @include flex-direction(row);
      @include justify-content(space-between);
      @include align-items(center); }

    div.total__row {
      padding: 10px 0;
      font-weight: 800;
      border-bottom: none; } } }
