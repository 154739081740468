body.not-logged-in.page-user {
  #content {
    padding-top: 30px;
    padding-bottom: 30px;

    form {
      @include susy-breakpoint($bk-728) {
        .form-item-name, .form-item-pass, .form-item-mail {}
        label {
          min-width: 180px; }
        .form-item-pass {
          margin-bottom: 60px; }

        .edit-account {} }

      div.addressfield-container-inline > div.form-item {
        float: none;
        margin-right: 0; } } } }

.app-user {
  display: block;
  @include container;
  navbar, ui-view {
    display: block; } }


profile {

  md-card {
    min-height: 300px;
    display: block;
    @include justify-content(start);
    justify-content: flex-start!important; }

  md-card-content {
    display: block; }

  div.user {

    .edit {
      position: relative;
      text-align: right;
      a {
        position: absolute;
        right: 2px;
        top: 2px; } }

    .picture {
      margin-bottom: 20px;
      padding: 8px;
      box-shadow: 0 0 1px 0px lightgrey;
      background-color: white;
      border-bottom: 20px solid $line-gray;
      @include display(flex);
      @include flex-direction(column);
      @include justify-content(space-around);
      @include align-items(center);

      img {
        max-width: 200px;
        border-radius: 50%;
        box-shadow: 0 0 3px 2px whitesmoke; }

      .name {
        color: $text-gray; } }

    .profile {
      margin: 0;
      h2 {
        margin-top: 0; }

      .extra {
        .icon {
          padding-left: 10px; } } }

    span, div {
      color: $text-gray;
 }      //font-size: 16px

    .item__row {
      padding: 6px 0;
      @include display(flex);
      @include align-items(center); } } }

[id*='edit-field-company'] {
  [class*='premise'] {
    label .form-required {
      display: none; } } }



body.page-user-register {
  #edit-account {
    background-color: white;
    padding: 2em 1em 1em;
    margin-bottom: 2em;
    border: 1px solid #ddd;
    border-radius: 4px; }

  #edit-twilio {
    border: none;
    legend {
 }      //display: none
    .panel-body {
      padding: 15px 0; } }

  .group-company-container {
    .field-name-field-company {
      fieldset {
        border: none;
        legend {
          display: none; }
        .panel-body {
          padding: 15px 0; } } } } }

