.box__content {
  @include display(flex);
  @include flex-direction(column);

  .box__content__item {
    padding: 8px 0; }

  .item__title {
    color: $primary;
    font-weight: 600;
    span {
      padding-right: 10px; } }
  .item__content {
    color: $text-gray;
 }    //font-weight: 400

  .visitor-dates:not(:last-child) {
    padding-bottom: 8px; } }
