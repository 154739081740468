
.navbar-user.navbar-bopa {
  padding-top: 40px;
  padding-bottom: 3px;
  margin-bottom: 1px;
  min-height: 150px;

  @include susy-breakpoint($bk-960) {
    min-height: inherit; }

  .links {
    min-height: 50px; }

  .account__menu__group {
    @include display(flex);
    width: 100%;
    min-height: 100px;
    @include susy-breakpoint($bk-960) {
      min-height: inherit; }

    ul {
      list-style: none;
      padding: 10px 0;
      margin: 0;
      //min-height: 90px
      @include display(flex);
      @include flex-direction(column);
      @include flex-grow(1);
      @include align-content(center);

      @include susy-breakpoint($bk-960) {
        @include flex-direction(row); }

      li {
        @include flex-grow(1);
        min-height: 40px;

        @include susy-breakpoint($bk-960) {
          @include flex-grow(0); }
        a.btn {
          width: 100%;
          background-color: #fff;
          border-color: $line-gray;
          border-radius: 1px;
          font-size: 16px;
          font-weight: 500;
          min-width: 200px;
          padding: 8px 12px; }



        &.active {
          a {
            background-color: black;
            color: white; } } } } } }
