auction-list, .layout__side {
  @include container(12);
  @include span(12); }

.home-component {
  @include clearfix;
  padding-bottom: 50px;

  .layout__main {
    @include susy-breakpoint($bkp-md) {
      @include span(9); } }

  .layout__side {
    > * {
      @include span(12);
      margin-bottom: 20px; }

    @include susy-breakpoint($bkp-md) {
      @include span(3);
      @include last; } } }
